import { Loader2 } from "lucide-react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom"; // Add this import
import Icon from "./Icon";

const modes = {
  light: "bg-white hover:bg-gray-100 focus:ring-gray-200 text-gray-800 border border-gray-300",
  primary: "bg-blue-600 hover:bg-blue-700 focus:ring-blue-300 text-white",
  success: "bg-green-600 hover:bg-green-700 focus:ring-green-300 text-white",
  icon: "bg-transparent hover:bg-gray-100 text-gray-700 rounded-full p-2",
  danger: "bg-red-600 hover:bg-red-700 focus:ring-red-300 text-white", // New mode added
};

const Button = ({
  title,
  icon,
  mode = "light",
  loading = false,
  disabled = false,
  type = "button",
  onClick,
  className = "",
  href, // New prop
}) => {
  const intl = useIntl();

  const baseClasses =
    "inline-flex items-center justify-center font-semibold focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors duration-200";
  const scheme = modes[mode] || modes.light;
  const disabledClasses = "opacity-50 cursor-not-allowed";
  const isIconOnly = !title;

  const _title = title ? intl.formatMessage({ id: title }) : undefined;

  const buttonClasses = `
    ${baseClasses}
    ${scheme}
    ${disabled || loading ? disabledClasses : ""}
    ${"px-4 h-12 rounded-md shadow-sm"}
    ${className}
  `;

  const ButtonContent = () => (
    <>
      {loading ? (
        <Loader2 className="animate-spin h-5 w-5" />
      ) : (
        <>
          {icon && <Icon code={icon} className={`w-5 h-5 ${isIconOnly ? "" : "mr-2"}`} />}
          {!isIconOnly && _title}
        </>
      )}
    </>
  );

  if (href) {
    return (
      <Link to={href} className={buttonClasses} aria-label={isIconOnly ? _title : undefined}>
        <ButtonContent />
      </Link>
    );
  }

  return (
    <button type={type} className={buttonClasses} disabled={disabled || loading} onClick={onClick} aria-label={isIconOnly ? _title : undefined}>
      <ButtonContent />
    </button>
  );
};

export default Button;
