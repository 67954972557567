import AppSelect from "@components/common/form/AppSelect";
import useApp from "src/hooks/useApp";
import { useCall } from "src/hooks/useCall";

export default function CallSettings() {
  /** */

  const {
    device,
    methods: { updateCallerID },
  } = useCall();

  const {
    PM: { profile },
  } = useApp();

  return (
    <AppSelect
      onChange={updateCallerID}
      value={device.callerID}
      options={profile?.callerIDs?.map(({ name, phone, _id }, _index) => ({ label: `${name} (${phone})`, value: _id }))}
      className="w-full"
      placeholder="T.SELECT_CALLER_ID"
      locale
    />
  );
}
