import { Outlet } from "react-router-dom";
import BottomMenu from "./BottomMenu";
import Header from "./Header";

export default function MasterLayout() {
  /** */

  const handleScroll = (event, headerHeight = 48, bottomMenuHeight = 48) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target;

    // Calculate the total scrollable height (excluding header and bottom menu)
    const totalScrollableHeight = scrollHeight - headerHeight - bottomMenuHeight;

    // Calculate the current scroll position (excluding header)
    const currentScrollPosition = scrollTop - headerHeight;

    // Calculate scroll percentage
    const scrollPercentage = (currentScrollPosition / (totalScrollableHeight - clientHeight)) * 100;

    // Determine if scrolled to bottom (with a small threshold)
    const isAtBottom = currentScrollPosition + clientHeight >= totalScrollableHeight - 20; // 20px threshold

    const data = {
      scrollPercentage: Math.max(0, Math.min(100, scrollPercentage)), // Clamp between 0 and 100
      isAtBottom,
      currentScrollPosition: Math.max(0, currentScrollPosition), // Ensure non-negative
    };
  };

  return (
    <div className="h-screen flex flex-col overflow-hidden">
      <Header className="flex-shrink-0" />
      <div className="flex-grow overflow-auto" onScroll={handleScroll}>
        <Outlet />
      </div>
      <BottomMenu className="flex-shrink-0" />
    </div>
  );
}
