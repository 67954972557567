import { toast } from "react-toastify";
import "./toast.css";
const props = {
  position: "top-right",
  autoClose: 1000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  progress: undefined,
};
const success = (text) =>
  // Success
  toast.success(text, {
    ...props,
    icon: <></>,
    closeButton: <></>,
    className: "bg-green-700 flex items-center text-white font-bold",
  });

// Warning
const warning = (text) =>
  toast.warning(text, {
    ...props,
    icon: <></>,
    closeButton: <></>,
    className: "bg-orange-500 flex items-center text-white font-bold",
  });

export default { success, warning };
