import { Link } from "react-router-dom";
import Icon from "./Icon";
import Locale from "./Locale";

const MenuItem = ({ to, icon, label }) => {
  return (
    <Link to={to} className="text-gray-600 hover:text-blue-600 flex flex-col items-center">
      <Icon code={icon} className="h-6 w-6" />
      <span className="text-xs mt-1">
        <Locale code={label} />
      </span>
    </Link>
  );
};

const BottomMenu = () => {
  return (
    <nav className="bg-white border-t border-gray-200 bottom-0 left-0 right-0">
      <div className="flex justify-around items-center h-16">
        <MenuItem to="/home" icon="home" label="T.HOME" />
        <MenuItem to="/leads" icon="users" label="T.LEADS" />
        <MenuItem to="/messenger" icon="messenger" label="T.MESSENGER" />
        <MenuItem to="/calls" icon="phone" label="T.CALLS" />
        <MenuItem to="/settings" icon="cog" label="T.SETTINGS" />
      </div>
    </nav>
  );
};

export default BottomMenu;
