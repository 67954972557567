import { AuthProvider } from "@context/AuthContext";
import { useEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddToHomeScreen from "./components/AddToHomeScreen";
import { AppProvider } from "./context/AppContext";
import { LocaleProvider } from "./context/LocaleContext";
import AppRoutes from "./routes/AppRoutes";

function App() {
  const queryClient = new QueryClient();
  const [isPWA, setIsPWA] = useState(false);

  useEffect(() => {
    setIsPWA(window.matchMedia("(display-mode: standalone)").matches || window.navigator.standalone === true);
  }, []);

  const isDevelopment = process.env.REACT_APP_ENV === "development";

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <AuthProvider>
          <AppProvider>
            <ToastContainer />
            <LocaleProvider>{isPWA || isDevelopment ? <AppRoutes /> : <AddToHomeScreen />}</LocaleProvider>
          </AppProvider>
        </AuthProvider>
      </Router>
    </QueryClientProvider>
  );
}

export default App;
