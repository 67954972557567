import BottomSheet from "@components/common/BottomSheet";
import Button from "@components/common/Button";
import { useState } from "react";
import { useCall } from "src/hooks/useCall";
import ActiveCall from "./ActiveCall";
import CallSettings from "./CallSettings";
import DialPad from "./DialPad";

const CallModal = ({ show, onClose, initialPhoneNumber = "" }) => {
  /** */

  const [number, setNumber] = useState(initialPhoneNumber);

  const { methods, call } = useCall();

  return (
    <BottomSheet
      show={show}
      title="Caller"
      onClose={onClose}
      body={
        <div className="flex flex-col space-y-4 h-full">
          <CallSettings />
          {call?.active && <ActiveCall />}
          <DialPad initialPhoneNumber={initialPhoneNumber} onPhoneNumberChange={setNumber} />
          <div className="flex justify-between items-center">
            <Button
              onClick={() => {
                if (number) methods.makeCall(number);
              }}
              icon="phone"
              mode="success"
              className="flex-1"
            />
          </div>
        </div>
      }
    />
  );
};

export default CallModal;
