import { NOTIFICATION_CODES } from "@config";
import { createContext, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import EventService from "../services/event.service";
import notificationService from "../services/notification.service";

const NotificationContext = createContext();
const { Provider } = NotificationContext;

const message_beep = new Audio("/media/mp3/beep.mp3");
const reminder_beep = new Audio("/media/mp3/beep2.mp3");
const lead_beep = new Audio("/media/mp3/beep3.mp3");
const transaction_beep = new Audio("/media/mp3/beep4.mp3");
const document_beep = new Audio("/media/mp3/beep5.mp3");

const _AUDIOS = {
  "NEW.MESSAGE": message_beep,
  "FORM.SUBMITTED": message_beep,
  "NEW.LEAD": lead_beep,
  "LEAD.UPDATED": lead_beep,
  "LEAD.ASSIGNED": lead_beep,
  "LEAD.ASSIGNED_GROUP": lead_beep,
  "NEW.TRANSACTION": transaction_beep,
  "NEW.DOCUMENT": document_beep,
  "NEW.REMINDER": reminder_beep,
};

// Beep
const beep = (code) => {
  console.log(code);
  if (!_AUDIOS[code]) return;
  _AUDIOS[code]
    .play()
    .then()
    .catch((error) => console.log(error));
};

const NotificationProvider = ({ children }) => {
  /** */

  const [notifications, setNotifications] = useState([]);

  const navigate = useNavigate();

  // Handle Notification Click
  const _handleNotificationClick = useCallback((code, payload) => {
    setTimeout(() => {
      if (code === "NEW.MESSAGE") return navigate(`/messenger/${payload?.leadID}/conversations/${payload?.conversationID}`);
      if (code === "NEW.LEAD") return navigate(`/leads/${payload?.leadID}/profile/overview`);
      if (code === "NEW.REMINDER") return navigate(`/leads/${payload?.leadID}/profile/overview`);
      if (code === "NEW.TRANSACTION") return navigate(`/leads/${payload?.leadID}/profile/transactions`);
      if (code === "NEW.DOCUMENT") {
        if (payload?.invoice) return navigate(`/leads/${payload?.leadID}/profile/invoices/${payload.invoice}`);
        if (payload?.offer) return navigate(`/leads/${payload?.leadID}/profile/offers/${payload.offer}`);
      }
      if (code === "LEAD.UPDATED") return navigate(`/leads/${payload?.leadID}/profile/overview`);
      if (code === "FORM.SUBMITTED") return navigate(`/leads/${payload?.leadID}/profile/overview`);
      if (code === "LEAD.ASSIGNED") return navigate(`/leads/${payload?.leadID}/profile/overview`);
      if (code === "LEAD.ASSIGNED_GROUP") return navigate(`/leads/${payload?.leadID}/profile/overview`);
    }, 0);
  }, []);

  // Hanlde notification Callback
  const _handleNotification = (payload = {}) => {
    if (payload.beep) beep(payload.code); // Play Sound
    return setNotifications(() => notificationService.add(payload)); // Persist Notification & Refresh State
  };

  //   Remove All Locally Persisted Notifications
  const _handleRemoveAll = () => {
    return setNotifications(() => notificationService.removeAll());
  };

  // Set Notificationa as "SEEN"
  const _handleSeen = (UUID) => {
    return setNotifications(() => notificationService.seen(UUID));
  };

  // Register Listeners
  useEffect(() => {
    setNotifications(() => notificationService.getAll());
    EventService.on(NOTIFICATION_CODES, _handleNotification); // Register Event Listener
    return () => {
      EventService.off(NOTIFICATION_CODES, _handleNotification); // Unregister Event Listener
    };
  }, []);

  return (
    <Provider
      value={{
        notifications,
        methods: {
          removeAll: _handleRemoveAll,
          seen: _handleSeen,
          click: _handleNotificationClick,
        },
      }}
    >
      {children}
    </Provider>
  );
};
export { NotificationContext, NotificationProvider };
