// App Version
export const APP_VERSION = process.env.REACT_APP_APP_VERSION;

// API Base URL
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// SOCKET API URL
export const SOCKET_BASE_URL = process.env.REACT_APP_SOCKET_BASE_URL;

// CDN Base URL
export const CDN_BASE_URL = process.env.REACT_APP_CDN_BASE_URL;

// Image Placeholders
export const PLACEHOLDERS = {
  thumbnail: "/media/avatars/blank.png",
};

// Hidden Toast Error Codes
export const HIDDEN_TOAST_ERROR_CODES = [401];

// Desktop Notification Codes
export const NOTIFICATION_CODES = [
  "NEW.MESSAGE",
  "NEW.LEAD",
  "LEAD.ASSIGNED",
  "LEAD.ASSIGNED_GROUP",
  "FORM.SUBMITTED",
  "LEAD.UPDATED",
  "NEW.DOCUMENT",
  "NEW.TRANSACTION",
  "NEW.REMINDER",
  "MESSAGE.DELIVERY",
];
