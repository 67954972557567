import ENDPOINTS from "@config/endpoints";
import { useEffect } from "react";
import { useMutation } from "react-query";
import { useAuth } from "./useAuth";

export default function useProfile() {
  /** */

  const { axios } = useAuth();

  const GPM = useMutation(() => axios.get(`${ENDPOINTS.user}/me/profile`));

  useEffect(() => {
    GPM.mutate();
  }, []);

  const refresh = () => GPM.mutate();

  return { profile: GPM.data ?? {}, methods: { refresh }, loading: GPM.loading };
}
