import { Suspense, lazy, useMemo } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { useAuth } from "src/hooks/useAuth";
import PrivateRoutes from "./PrivateRoutes";

const AccountsPage = lazy(() => import("src/modules/auth/AccountsPage"));
const LoginPage = lazy(() => import("src/modules/auth/LoginPage"));
const LeadPage = lazy(() => import("src/modules/leads/LeadPage"));
const AdminHome = lazy(() => import("src/modules/home/admin/AdminHome"));
const AgentHome = lazy(() => import("src/modules/home/agent/AgentHome"));
const MessengerPage = lazy(() => import("src/modules/messenger/MessengerPage"));
const SettingsPage = lazy(() => import("src/modules/settings/SettingsPage"));
const CallsPage = lazy(() => import("src/modules/calls/CallPage"));
const NotificationPage = lazy(() => import("src/modules/notifications/NotificationPage"));

const AppRoutes = () => {
  const { isLoggedIn, isAdmin } = useAuth();

  // Public Routes Definition
  const PUBLIC = useMemo(
    () => [
      { path: "/login", element: <LoginPage /> },
      { path: "/accounts", element: <AccountsPage /> },
    ],
    []
  );

  // Private Routes Definition
  const PRIVATE = useMemo(
    () => [
      { path: "/home", element: !isAdmin ? <AdminHome /> : <AgentHome /> },
      { path: "/leads/*", element: <LeadPage /> },
      { path: "/messenger/*", element: <MessengerPage /> },
      { path: "/settings/*", element: <SettingsPage /> },
      { path: "/calls/*", element: <CallsPage /> },
      { path: "/notifications", element: <NotificationPage /> },
    ],
    [isAdmin, isLoggedIn]
  );

  // Public Routes Wrapper
  const PublicRoutes = ({ isLoggedIn }) => {
    return isLoggedIn ? <Navigate to="/leads" replace /> : <Outlet />;
  };

  // Suspensed View
  const SV = ({ element }) => <Suspense fallback={<>Loading Component</>}>{element}</Suspense>;

  return (
    <Routes>
      <Route element={<PublicRoutes isLoggedIn={isLoggedIn} />}>
        {PUBLIC?.map(({ path, element }) => (
          <Route key={path} path={path} element={<SV element={element} />} />
        ))}
      </Route>
      <Route element={<PrivateRoutes isLoggedIn={isLoggedIn} />}>
        {PRIVATE?.map(({ path, element }) => (
          <Route key={path} path={path} element={<SV element={element} />} />
        ))}
        <Route path="*" element={<Navigate to="/home" />} replace />
      </Route>
      <Route path="*" element={isLoggedIn ? <Navigate to="/home" replace /> : <Navigate to="/landing" replace />} />
    </Routes>
  );
};

export default AppRoutes;
