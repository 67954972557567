import Button from "@components/common/Button";
import { useEffect, useState } from "react";

const DialPad = ({ initialPhoneNumber = "", onPhoneNumberChange }) => {
  const [phoneNumber, setPhoneNumber] = useState(initialPhoneNumber);

  useEffect(() => {
    onPhoneNumberChange(phoneNumber);
  }, [phoneNumber, onPhoneNumberChange]);

  const handleNumberInput = (value) => {
    setPhoneNumber((prevNumber) => prevNumber + value);
  };

  const handleDelete = () => {
    setPhoneNumber((prevNumber) => prevNumber.slice(0, -1));
  };

  const dialPad = [
    ["1", "2", "3"],
    ["4", "5", "6"],
    ["7", "8", "9"],
    ["*", "0", "#"],
  ];

  return (
    <div className="flex flex-col h-full space-y-4">
      <div className="flex items-center space-x-2">
        <input type="text" value={phoneNumber} className="w-full h-16 bg-gray-100 border-none outline-none rounded-lg text-center text-2xl font-semibold" />
        <Button onClick={handleDelete} className="w-16 h-16" mode="icon" icon="backspace" />
      </div>
      <div className="grid grid-cols-3 gap-4 flex-grow">
        {dialPad.flat().map((digit) => (
          <button key={digit} onClick={() => handleNumberInput(digit)} className="bg-gray-200 hover:bg-gray-300 rounded-full p-4 text-xl font-semibold">
            {digit}
          </button>
        ))}
      </div>
    </div>
  );
};

export default DialPad;
