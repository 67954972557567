import { NOTIFICATION_CODES, SOCKET_BASE_URL } from "@config";
import { io } from "socket.io-client";
import EventService from "./event.service";

class SocketService {
  socket;

  connect(token) {
    if (!token) {
      console.log("Not Logged In");
      return;
    }

    this.socket = io(SOCKET_BASE_URL, {
      auth: { token },
      transports: ["websocket"],
      autoConnect: true,
      reconnection: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 5000,
    });

    this.socket.on("connect", () => {
      console.log("Connected to Socket.IO server");
    });

    this.socket.on("disconnect", () => {
      console.log("Disconnected from Socket.IO server");
    });

    this.socket.on("connect_error", (error) => {
      console.error("Connection error:", error);
    });

    // Set up listeners for desktop notifications
    NOTIFICATION_CODES.forEach((code) => {
      this.socket.on(code, (payload) => EventService.emit(code, payload));
    });
  }

  disconnect() {
    if (this.socket) {
      // Remove listeners for desktop notifications
      NOTIFICATION_CODES.forEach((code) => {
        this.socket.off(code);
      });
      this.socket.disconnect();
    }
  }

  emit(event, data) {
    if (this.socket) {
      this.socket.emit(event, data);
    }
  }

  on(event, callback) {
    if (this.socket) {
      this.socket.on(event, callback);
    }
  }

  off(event, callback) {
    if (this.socket) {
      this.socket.off(event, callback);
    }
  }
}

export const socketService = new SocketService();
