const listeners = new Map();

const EventService = {
  on: (events, fn) => {
    if (!Array.isArray(events) || typeof fn !== "function") {
      throw new Error("Invalid arguments");
    }
    events.forEach((code) => {
      if (!listeners.has(code)) listeners.set(code, new Set());
      listeners.get(code).add(fn);
    });
  },

  off: (events, fn) => {
    if (!Array.isArray(events) || typeof fn !== "function") {
      throw new Error("Invalid arguments");
    }
    events.forEach((code) => {
      const eventListeners = listeners.get(code);
      if (eventListeners) eventListeners.delete(fn);
    });
  },

  emit: (code, payload) => {
    const eventListeners = listeners.get(code);
    if (eventListeners) {
      eventListeners.forEach((fn) => fn(payload));
    }
  },

  // New method to remove all listeners for an event
  removeAllListeners: (code) => {
    listeners.delete(code);
  },
};

export default EventService;
