import Locale from "@components/common/Locale";
import Spinner from "@components/common/Spinner";
import ENDPOINTS from "@config/endpoints";
import { useEffect } from "react";
import { useMutation } from "react-query";
import { Link } from "react-router-dom";
import useApp from "src/hooks/useApp";
import { useAuth } from "src/hooks/useAuth";

export default function ActiveCallProfile({ callID }) {
  /** */

  const { axios } = useAuth();

  // Get Whois Mutation
  const GWM = useMutation(() => axios.get(`${ENDPOINTS.whois}/${callID}`));

  const {
    GM: { countries },
  } = useApp();

  const lead = GWM.data?.lead ?? {};

  useEffect(() => GWM.mutate(), [callID]);

  if (!lead) return null;

  return (
    <div className="px-4 py-7 border flex flex-col  items-center relative">
      {GWM.isLoading && <Spinner />}
      {GWM.data && (
        <>
          <span className="  font-bold mb-1">
            {lead?.firstName ?? ""} {lead?.lastName ?? ""}
          </span>
          {lead?.country && <span className="text-gray-500 text-base mb-5">{countries?.find(({ code }) => code === lead?.country)?.name}</span>}
          <span className="text-gray-500 hover:text-blue-500">
            <Link to={`/leads/${lead?._id}/profile/overview`}>
              <Locale code="T.CLICK_TO_SEE_DETAILS" />
            </Link>
          </span>
        </>
      )}
    </div>
  );
}
