import CallModal from "@components/modals/call/CallModal";
import { createContext, useState } from "react";

export const LayoutContext = createContext();

export const LayoutProvider = ({ children }) => {
  /** */

  // Call Modal
  const [callModal, setCallModal] = useState({ enabled: false, number: "" });

  const call = {
    enabled: callModal.enabled,
    number: callModal.number,
    open: (number) => setCallModal({ enabled: true, number }),
    close: () => setCallModal({ enabled: false }),
  };

  const value = {
    call,
  };

  return (
    <LayoutContext.Provider value={value}>
      {children}
      {callModal.enabled && <CallModal show initialPhoneNumber={callModal.number} onClose={call.close} />}
    </LayoutContext.Provider>
  );
};
