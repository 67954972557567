import MasterLayout from "@components/common/MasterLayout";
import { LayoutProvider } from "@context/LayoutContext";
import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { CallProvider } from "src/context/CallContext";
import { NotificationProvider } from "src/context/NotificationContext";
import { useAuth } from "src/hooks/useAuth";
import { socketService } from "src/services/socket.service";

// Private Routes Wrapper
export default function PrivateRoutes() {
  /** */

  const { isLoggedIn, token } = useAuth();

  useEffect(() => {
    if (isLoggedIn) {
      socketService.connect(token);
    } else {
      socketService.disconnect();
    }
  }, [isLoggedIn]);

  return isLoggedIn ? (
    <NotificationProvider>
      <CallProvider>
        <LayoutProvider>
          <MasterLayout />
        </LayoutProvider>
      </CallProvider>
    </NotificationProvider>
  ) : (
    <Navigate to="/login" replace />
  );
}
