import { X } from "lucide-react";
import Locale from "./Locale";

export default function BottomSheet({ show, title, body, footer, onClose, bodyClassName }) {
  if (!show) return null;

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-50">
      <div className="fixed inset-x-0 bottom-0 bg-white rounded-t-xl shadow-lg transform transition-all duration-300 ease-in-out max-h-[80vh] flex flex-col">
        <div className="px-4 py-4 border-b border-gray-200">
          <div className="flex justify-between items-center">
            <h2 className="text-lg font-medium text-gray-900">
              <Locale code={title} />
            </h2>
            <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
              <X className="h-6 w-6" />
            </button>
          </div>
        </div>

        <div className={`flex-grow overflow-y-auto ${bodyClassName || "px-4 py-4"}`}>{body}</div>
        {footer && <div className="px-4 py-4 border-t border-gray-200">{footer}</div>}
      </div>
    </div>
  );
}
