import Button from "@components/common/Button";
import { useCall } from "src/hooks/useCall";
import ActiveCallProfile from "./ActiveCallProfile";

export default function ActiveCall() {
  /** */

  const { call, device } = useCall();

  // Call Connecting ....
  if (call?.status === "connecting") return <div className="p-4 border border-dashed flex items-center justify-center">Connecting ....</div>;

  // Call Disconnected ....
  if (call?.status === "disconnect") return <div className="p-4 border border-dashed flex items-center justify-center">Disconnected</div>;

  // Call Accepted
  return (
    <div className="flex flex-col space-y-2">
      {/* Number */}
      <div className="flex flex-col space-y-2">
        {/* Control Buttons */}
        {call?.direction === "inbound" && call?.status === "pending" && (
          <Button onClick={() => call?.instance?.accept()} mode="success" title="T.ANSWER" icon="phone" />
        )}
        <Button
          onClick={() => (call?.status === "pending" ? call?.instance?.reject() : device?.instance?.disconnectAll())}
          mode="danger"
          title="T.DECLINE"
          icon="phone-x"
        />
      </div>
      {/* Profile */}
      <ActiveCallProfile callID={call?.callID} />
    </div>
  );
}
