import LoadingScreen from "@components/screens/loading/LoadingScreen";
import useCompany from "@hooks/useCompany";
import useGlobal from "@hooks/useGlobal";
import useProfile from "@hooks/useProfile";
import { createContext, useState } from "react";

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  /** */

  const [locale, setLocale] = useState(localStorage.getItem("locale") || "en");

  const PM = useProfile(); // Profile Manager
  const GM = useGlobal(); // Global Manager
  const CM = useCompany(); // Company Manager

  return (
    <AppContext.Provider
      value={{
        PM,
        CM,
        GM,
        locale,
        setLocale: (code) => {
          localStorage.setItem("locale", code);
          setLocale(code);
        },
      }}
    >
      {[GM, CM, PM].some((x) => x.loading) ? <LoadingScreen /> : children}
    </AppContext.Provider>
  );
};
