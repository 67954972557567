import ENDPOINTS from "@config/endpoints";
import { HIDDEN_TOAST_ERROR_CODES } from "@config/index";
import Axios from "axios";
import { createContext, useCallback, useEffect, useMemo, useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import toast from "src/helpers/toast";

export const AuthContext = createContext();

// Create Axios Instance & Setup Interceptors
const axios = Axios.create();
axios.defaults.headers.Accept = "application/json";
axios.interceptors.response.use(
  (response) => {
    // Check the status in the response
    if (response.data?.status !== 200) {
      const message = response.data.error?.message || "An error occurred";
      const fields = response.data.error?.fields || {};
      if (!HIDDEN_TOAST_ERROR_CODES.includes(response.data.status)) toast.warning(Array.isArray(message) ? message?.join(", ") : message);
      throw new Error(JSON.stringify({ message, fields }));
    }
    return response.data.data; // Return the data if status is 200
  },
  (error) => {
    // Handle the error response
    const errorMessage = error?.response?.data?.error?.message || "An unexpected error occurred";
    throw new Error(errorMessage);
  }
);

export const AuthProvider = ({ children }) => {
  /** */

  const [token, setToken] = useState(localStorage.getItem("token"));
  const [user, setUser] = useState(null);

  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const updateAxiosAuth = useCallback((newToken) => {
    if (newToken) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${newToken}`;
    } else {
      delete axios.defaults.headers.common["Authorization"];
    }
  }, []);

  const login = useCallback(
    (newToken) => {
      setToken(newToken);
      localStorage.setItem("token", newToken);
      updateAxiosAuth(newToken);
    },
    [updateAxiosAuth]
  );

  const logout = useCallback(() => {
    setToken(null);
    setUser(null);
    localStorage.removeItem("token");
    updateAxiosAuth(null);
    navigate("/login");
  }, [navigate, updateAxiosAuth]);

  const isAccessible = useCallback(
    (code) => {
      if (!code || user?.type === "company") return true;
      return Boolean(user?.permissions?.includes(code));
    },
    [user]
  );

  const isAdmin = useCallback(() => user?.type === "company", [user]);

  // Verify Token
  const verify = useMutation((token) => axios.post(`${ENDPOINTS.app.auth}/verify`, { token }), {
    onSuccess: (response) => {
      setUser(response);
      setLoading(false);
    },
    onError: () => {
      logout();
      setLoading(false);
    },
  });

  // Check Persisted Token
  useEffect(() => {
    if (token) {
      updateAxiosAuth(token);
      verify.mutate(token);
    } else {
      setLoading(false);
    }
  }, [token, updateAxiosAuth]);

  // Make CDN URL
  const makeCDNURL = useCallback(
    (mediaID) => {
      return `${ENDPOINTS.cdn}/app/medias/${mediaID}/download?token=${token}`;
    },
    [token]
  );

  const contextValue = useMemo(
    () => ({
      isLoggedIn: Boolean(user),
      token,
      user,
      login,
      logout,
      isAccessible,
      isAdmin,
      makeCDNURL,
      axios,
    }),
    [token, user, login, logout, isAccessible, isAdmin, makeCDNURL]
  );

  return <AuthContext.Provider value={contextValue}>{loading ? "Authentication ... " : children}</AuthContext.Provider>;
};
