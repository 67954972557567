import ENDPOINTS from "@config/endpoints";
import { AuthContext } from "@context/AuthContext";
import { useContext, useEffect } from "react";
import { useMutation } from "react-query";
import { useAuth } from "./useAuth";

export default function useCompany() {
  /** */

  const { axios } = useAuth();

  const { isLogedIn } = useContext(AuthContext);

  // Get Company Mutation
  const GCM = useMutation(() => axios.get(`${ENDPOINTS.company}/basic`));

  useEffect(() => {
    if (isLogedIn) GCM.mutate();
  }, [isLogedIn]);

  // Refetch Company
  const refresh = () => GCM.mutate();

  // Company Profile
  const company = GCM.data ?? {};

  // Check If Service Enabled
  const isServiceEnabled = (codes) => {
    if (!codes || codes?.length === 0) return true;
    if (Array.isArray(codes)) return codes?.some((code) => company?.services?.includes(code));
    else return company?.services?.includes(codes);
  };

  return { profile: company, services: company?.services, isServiceEnabled, loading: GCM.isLoading, methods: { refresh } };
}
