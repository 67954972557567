import { useState } from "react";
import { Link } from "react-router-dom";
import Icon from "./Icon";
import NavigationDrawer from "./NavigationDrawer";

const Header = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <>
      <header className="bg-blue-600 text-white p-4 flex justify-between items-center">
        <div className="flex items-center">
          <Icon code="menu" className="h-6 w-6 mr-4 cursor-pointer" onClick={toggleDrawer} />
          <h1 className="text-xl font-bold">POB CRM</h1>
        </div>
        <div className="flex items-center">
          <Link to="/notifications">
            <Icon code="bell" className="h-6 w-6 cursor-pointer" />
          </Link>
        </div>
      </header>
      <NavigationDrawer isOpen={isDrawerOpen} onClose={toggleDrawer} />
    </>
  );
};

export default Header;
