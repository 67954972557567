class NotificationService {
  constructor() {
    // Load notifications from localStorage if available
    const savedNotifications = localStorage.getItem("notifications");
    this.notifications = savedNotifications ? JSON.parse(savedNotifications) : [];
  }

  // Save notifications to localStorage
  _saveToLocalStorage() {
    localStorage.setItem("notifications", JSON.stringify(this.notifications));
  }

  // Add a new notification
  add(notification) {
    this.notifications.push(notification);
    this._saveToLocalStorage();
    return this.notifications;
  }

  // Get all notifications
  getAll() {
    return this.notifications;
  }

  // Remove all notifications
  removeAll() {
    this.notifications = [];
    this._saveToLocalStorage();
    return this.notifications;
  }

  // Mark a notification as seen
  seen(UUID) {
    this.notifications = this.notifications.map((notification) => (notification.UUID === UUID ? { ...notification, seen: true } : notification));
    this._saveToLocalStorage();
    return this.notifications;
  }
}

const notificationService = new NotificationService();
export default notificationService;
