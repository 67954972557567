import {
  AlertTriangle,
  Bell,
  Calendar,
  Check,
  CheckCircle,
  ChevronRight,
  Circle,
  CircleAlertIcon,
  Clock,
  Cog,
  CreditCard,
  DeleteIcon,
  EllipsisIcon,
  FileArchive,
  FileText,
  Filter,
  FilterIcon,
  GlobeIcon,
  HashIcon,
  Home,
  Inbox,
  Info,
  LoaderIcon,
  MailIcon,
  MessageSquare,
  Mic,
  Paperclip,
  Pencil,
  PhoneIcon,
  PhoneIncoming,
  PhoneOffIcon,
  PhoneOutgoing,
  PlusIcon,
  SearchIcon,
  Send,
  Tag,
  Trash,
  UserIcon,
  Users2,
  XCircle,
} from "lucide-react";

const _ICONS = {
  bell: Bell,
  calendar: Calendar,
  card: CreditCard,
  archive: FileArchive,
  text: FileText,
  home: Home,
  tag: Tag,
  circle: Circle,
  clock: Clock,
  light: Info,
  success: CheckCircle,
  primary: Info,
  warning: AlertTriangle,
  danger: XCircle,
  "check-circle": CheckCircle,
  check: Check,
  user: UserIcon,
  globe: GlobeIcon,
  mail: MailIcon,
  phone: PhoneIcon,
  plus: PlusIcon,
  search: SearchIcon,
  filter: Filter,
  ellipsis: EllipsisIcon,
  backspace: DeleteIcon,
  "phone-x": PhoneOffIcon,
  spinner: LoaderIcon,
  users: Users2,
  messenger: MessageSquare,
  cog: Cog,
  "phone-inbound": PhoneIncoming,
  "phone-outbound": PhoneOutgoing,
  x: XCircle,
  "chevron-right": ChevronRight,
  "circle-alert": CircleAlertIcon,
  paperclip: Paperclip,
  send: Send,
  mic: Mic,
  trash: Trash,
  pencil: Pencil,
  hashtag: HashIcon,
  inbox: Inbox,
  filter: FilterIcon,
};

export default function Icon({ code, ...props }) {
  const Component = _ICONS[code];
  if (!Component) return <></>;
  return <Component {...props} />;
}
