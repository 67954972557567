import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Icon from "./Icon";
import Locale from "./Locale";

const MenuItem = ({ to, icon, label, onClose }) => {
  return (
    <Link to={to} className="text-gray-600 hover:text-blue-600 flex items-center p-4" onClick={onClose}>
      <Icon code={icon} className="h-6 w-6 mr-3" />
      <span className="text-base">
        <Locale code={label} />
      </span>
    </Link>
  );
};

const NavigationDrawer = ({ isOpen, onClose }) => {
  const drawerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (drawerRef.current && !drawerRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("pointerdown", handleClickOutside);
    } else {
      document.removeEventListener("pointerdown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("pointerdown", handleClickOutside);
    };
  }, [isOpen, onClose]);

  return (
    <div
      ref={drawerRef}
      className={`fixed inset-0 z-50 transition-transform transform ${isOpen ? "translate-x-0" : "-translate-x-full"} bg-white shadow-lg w-3/4 duration-500`}
    >
      <div className="flex justify-end p-4">
        <button onClick={onClose} className="text-gray-600 hover:text-blue-600">
          <Icon code="x" className="h-6 w-6" />
        </button>
      </div>
      <nav className="flex flex-col">
        <MenuItem to="/home" icon="home" label="T.HOME" onClose={onClose} />
        <MenuItem to="/leads" icon="users" label="T.LEADS" onClose={onClose} />
        <MenuItem to="/messenger" icon="messenger" label="T.MESSENGER" onClose={onClose} />
        <MenuItem to="/calls" icon="phone" label="T.CALLS" onClose={onClose} />
        <MenuItem to="/settings" icon="cog" label="T.SETTINGS" onClose={onClose} />
      </nav>
    </div>
  );
};

export default NavigationDrawer;
