import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import BottomSheet from "../BottomSheet";
import Icon from "../Icon";

const AppSelect = ({ options = [], value, onChange, placeholder, locale, multiple = false }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(multiple ? [] : null);
  const intl = useIntl();

  useEffect(() => {
    if (multiple) {
      setSelectedOptions(options.filter((option) => value?.includes(option.value)));
    } else {
      setSelectedOptions(options.find((option) => option.value === value) || null);
    }
  }, [value, multiple, options]);

  const handleOptionClick = (option) => {
    if (multiple) {
      const updatedSelection = selectedOptions.some((item) => item.value === option.value)
        ? selectedOptions.filter((item) => item.value !== option.value)
        : [...selectedOptions, option];
      setSelectedOptions(updatedSelection);
      onChange(updatedSelection.map((item) => item.value));
    } else {
      setSelectedOptions(option);
      onChange(option.value);
      setIsOpen(false);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const isOptionSelected = (option) => {
    return multiple ? selectedOptions.some((item) => item.value === option.value) : selectedOptions && selectedOptions.value === option.value;
  };

  const bodyContent = (
    <ul className="py-1">
      {options.map((option) => (
        <li
          key={option.value}
          className={`${
            isOptionSelected(option) ? "text-blue-600 font-semibold" : "text-gray-900"
          } cursor-pointer select-none relative py-3 px-4 hover:bg-gray-100`}
          onClick={() => handleOptionClick(option)}
        >
          <span className="block truncate">{locale ? intl.formatMessage({ id: option.label ?? "-" }) : option.label}</span>
          {isOptionSelected(option) && (
            <span className="absolute inset-y-0 right-0 flex items-center pr-4">
              <Icon code="check" className="w-5 h-5 text-blue-600" aria-hidden="true" />
            </span>
          )}
        </li>
      ))}
    </ul>
  );

  const getDisplayValue = () => {
    if (multiple) {
      if (selectedOptions.length === 0) {
        return locale ? intl.formatMessage({ id: placeholder ?? "-" }) : placeholder || "Select options";
      }
      return selectedOptions.map((option) => (locale ? intl.formatMessage({ id: option.label ?? "-" }) : option.label)).join(", ");
    } else {
      if (!selectedOptions) {
        return locale ? intl.formatMessage({ id: placeholder ?? "-" }) : placeholder || "Select an option";
      }
      return locale ? intl.formatMessage({ id: selectedOptions.label ?? "-" }) : selectedOptions.label;
    }
  };

  return (
    <>
      <button
        type="button"
        className="w-full px-4 h-12 text-left bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 overflow-hidden"
        onClick={() => setIsOpen(true)}
        aria-haspopup="listbox"
        aria-expanded={isOpen}
      >
        <span className="block truncate">{getDisplayValue()}</span>
        <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
          <Icon code="chevron-down" className="w-5 h-5 text-gray-400" aria-hidden="true" />
        </span>
      </button>
      <BottomSheet show={isOpen} title={placeholder || (multiple ? "Select options" : "Select an option")} body={bodyContent} onClose={handleClose} />
    </>
  );
};

export default AppSelect;
