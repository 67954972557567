import { useState } from "react";

const AddToHomeScreen = () => {
  const [locale, setLocale] = useState("en");

  const handleLocaleChange = (newLocale) => {
    setLocale(newLocale);
  };

  const instructions = {
    en: {
      title: "Add to Home Screen",
      description: "To install this app on your device, follow these steps:",
      steps: [
        "Open the browser menu (usually three dots or lines in the top right corner).",
        'Select "Add to Home screen" or "Install app".',
        "Follow the prompts to add the app to your home screen.",
      ],
    },
    tr: {
      title: "Ana Ekrana Ekle",
      description: "Bu uygulamayı cihazınıza yüklemek için şu adımları izleyin:",
      steps: [
        "Tarayıcı menüsünü açın (genellikle sağ üst köşedeki üç nokta veya çizgi).",
        '"Ana ekrana ekle" veya "Uygulamayı yükle" seçeneğini seçin.',
        "Uygulamayı ana ekranınıza eklemek için istemleri izleyin.",
      ],
    },
  };

  const { title, description, steps } = instructions[locale];

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gradient-to-r from-blue-500 to-indigo-600 text-white p-6">
      <img src="/logo.png" alt="App Logo" className="w-32 h-32 mb-6 rounded-full shadow-lg" />
      <h1 className="text-2xl uppercase mb-2">Patientsonboard CRM</h1>
      <h2 className="text-xl font-bold mb-4">{title}</h2>
      <p className="mb-6 text-lg">{description}</p>
      <ol className="list-decimal list-inside text-left text-lg space-y-2 mb-6">
        {steps.map((step, index) => (
          <li key={index} className="mb-2">
            {step}
          </li>
        ))}
      </ol>
      <div className="flex items-center space-x-4 text-lg">
        <span className={`cursor-pointer ${locale === "en" ? "font-bold underline" : ""}`} onClick={() => handleLocaleChange("en")}>
          English
        </span>
        <span>|</span>
        <span className={`cursor-pointer ${locale === "tr" ? "font-bold underline" : ""}`} onClick={() => handleLocaleChange("tr")}>
          Türkçe
        </span>
      </div>
    </div>
  );
};

export default AddToHomeScreen;
